@import '~react-image-gallery/styles/css/image-gallery.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e2323;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.gm-style iframe + div {
  border: none !important;
}

.image-gallery-thumbnails-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.image-gallery-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-gallery-image-custom {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-gallery-slides{
  min-height: 480px;
}

@media only screen and (min-width: 1200px) {
  .image-gallery-thumbnail {
    width: 102.9px;
  }
}

@media only screen and (min-width: 1500px) {
  .image-gallery-thumbnail {
    width: 127px;
  }
}

@media only screen and (min-width: 1800px) {
  .image-gallery-thumbnail {
    width: 165px;
  }
} 
